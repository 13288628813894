import { flockTheme, PasswordResetForm } from '@flock/shared-ui'
import { ThemeProvider } from '@mui/system'
import React from 'react'

const PasswordReset = () => (
  <ThemeProvider theme={flockTheme}>
    <PasswordResetForm />
  </ThemeProvider>
)

export default PasswordReset
